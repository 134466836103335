
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-Black.ttf');
}

body {
    background-color: #f4f2f2;
}

.profile {
    background-image: radial-gradient( circle 788px at 0.7% 3.4%,  rgb(87, 110, 203) 0%, rgba(255,255,255,1) 90% );
}

* {
    margin: 0;
    padding: 0;
}


.custom-shape-divider-top-1685229460 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1685229460 svg {
    position: relative;
    display: block;
    width: calc(172% + 1.3px);
    height: 1100px;
}

.custom-shape-divider-top-1685229460 .shape-fill {
    fill: #ebebeb;
}

@media only screen and (max-width: 600px) {
    .custom-shape-divider-top-1685229460 svg {
        position: relative;
        display: block;
        width: calc(242% + 1.3px);
        height:700px;
    }
}

.ripple-effect {
    animation: ripple 25s infinite;
  }
  
  @keyframes ripple {
    0%, 100% {
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    }
    25% {
      border-radius: 40% 60% 60% 40% / 40% 40% 60% 60%;
    }
    35% {
        border-radius: 75% 25% 70% 30% / 30% 30% 70% 70%; 
    }
    50% {
      border-radius: 30% 70% 70% 30% / 60% 60% 30% 30%;
    }
    75% {
      border-radius: 20% 80% 80% 20% / 50% 50% 50% 50%;
    }
  }
  